import React from 'react'
import { Link } from 'gatsby'

import Image from './Image'
import './EventCard.css'

const EventCard = ({
  featured_image_url,
  title,
  summary,
  browser_url,
  id,
  event_type,
  className = '',
  frontmatter,
  timeslots,
  ...props
}) => (
  // <Link to={"/test/"+id} className={`EventCard ${className}`}>
  <a href={browser_url} target="_blank" className={`EventCard ${className}`}>
    {featured_image_url && (
      <div className="EventCard--Image relative">
        <Image  src={featured_image_url} alt={title} />
      </div>
    )}
    <div className="EventCard--Content">
      {title && <h3 className="EventCard--Title">{title}</h3>}
      <div className="EventCard--Category">
        {event_type}
      </div>
     
      {timeslots[0].start_date = new Date(timeslots[0].start_date * 1000).toDateString()}
      {}
      {timeslots && (
              <time
                className="SinglePost--Meta--Date"
                itemProp="dateCreated pubdate datePublished"
                date={new Date(timeslots[0].start_date * 1000).toDateString()}
              >
                {/* {timeslots[0].start_date} */}
              </time>
            )}
      {summary && <div className="EventCard--Excerpt">{summary}</div>} 
    </div>
  {/* </Link> */}
  </a>
)

export default EventCard
