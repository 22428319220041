import React, {useState, useEffect, SetStateAction} from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../components/Layout'
import EventSection from '../components/EventSection'


 /**
 * filter posts by category.
 *
 * @param {props} object
 * @param {state} object
 * @param {contentType} string
 */


export const EventsIndexTemplate = ({state,page}) => {
// ----------------------
  // RUNTIME DATA FETCHING
  // ----------------------
  const [data, setData] = useState(0)
  useEffect(() => {
    
    // get data from GitHub api 
    fetch(`https://api.mobilize.us/v1/events?organization_id=2144&timeslot_start=gte_now`)
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        setData(resultData)
      }) // set data for the number of stars
  }, [])
  
  return(

    <main className="Events">
       <section className="section ">
       <div className="container">
           <EventSection issues={data.data} />

        </div>      
       </section>
    </main>
  )

  }

  
// Export Default EndorseIndex for front-end
const EventsIndex = ({data:{ state,fetch,page} }) => (
    <Layout>
      <EventsIndexTemplate
      {...state}
      {...fetch}
      {...page}
      />
    </Layout>
  )
  
  export default EventsIndex

    
  export const pageQuery = graphql`
  ## Query for EventsIndex data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query EventsIndex($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        featuredImage
        donatevalues
      }
    }
  }
  `